import React from "react";
import styles from "./CharitySection.module.scss";

import Logo from "./assets/WorldCentralKitchenLogo.png"
import Image from "./assets/wck.png"

import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const CharitySection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'charity'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>
          <div className={styles.shadow_container}>
            <div className={styles.container}>

                <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start', justifyContent: 'start'}}>

                  <h2 className={styles.the}>the</h2>
                  <h3 className={styles.charity}>charity</h3>
                  <h4 className={styles.helping}>helping others</h4>
                  
                </div>

              </div>

            </div>

            <div className={styles.containerContent}>

              <div className={styles.mobileContainer} >
                <div className={styles.imageContainer} >
                  <img className={styles.image} src={Image} alt="Benefit" />
                  <img className={styles.logo} src={Logo} alt="World Charity Kitchen Logo" />
                </div>

                <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start', gap: '25px'}}>
                <h5 className={styles.headline}>World Central Kitchen</h5>

                <p className={styles.text}>The release of this song directly supports World Central Kitchen, with a 20% of all proceeds donated to the charity.</p>
                <p className={styles.text}>By purchasing a bit of this song you are contributing to providing meals and relief in disaster-affected areas worldwide, turning music into a powerful tool for making a real difference.</p>
                <p className={styles.text}>Find out more about World Central Kitchen <a target="_blank" rel="noreferrer" href="https://wck.org/">here</a></p>

                <div className={styles.socialsContainerRow}>
                  <div className={styles.socialsContainer}>
                    
                  </div>
                  <div>
                    <div >
                      <RegisterInvite customClass={'charity-waitlist'}  onSubmit={onSubmit} />
                    </div>            
                  </div>
                </div>
              </div>

            </div>
          </div>
                  
    </div>
  )

};