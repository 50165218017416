import React, { useRef, useState }  from "react";
import styles from "./VideoSection.module.scss";

import playBtn from "./assets/play-icon.svg"

import Video from "./assets/sizzle-cut-low.mp4"
import Poster from "./assets/video-bg.png"

export const VideoSection = () => {
  const videoRef = useRef(null); 
  const [isPlaying, setIsPlaying] = useState(false); 

  const handlePlay = () => {
    setIsPlaying(true); 
    videoRef.current.play(); 
  };

  return (
    <>
    <div className={styles.background}>
      <video className={styles.video} preload="metadata" poster={Poster} ref={videoRef} controls={isPlaying}>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <img className={styles.playBtn} onClick={handlePlay} src={playBtn} alt=""  />
      )}
    </div>
    </>
  )
};