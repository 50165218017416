import React from 'react';

// libs
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';
import { IntercomProvider } from 'react-use-intercom';

// components
import { App } from "./components/common";

// assets
import './styles/index.scss';

import graphqlClient from './graphql/client';
import store from './store/store';

const onHide = () => {
    window.Intercom('shutdown');
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ApolloProvider client={graphqlClient}>
        <IntercomProvider appId={'yhh9c702'} autoBoot={false} onHide={onHide}>
          <App />
        </IntercomProvider>
      </ApolloProvider>
    </StoreProvider>
  </React.StrictMode>
);