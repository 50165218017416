import React from "react";
import styles from "./SongSection.module.scss";

import CoverImage from "./assets/cover.jpg"
import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const SongSection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'song'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>
          <div className={styles.shadow_container}>
            
            <div className={styles.container}>

                <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>
                  <img className={styles.coverimage} src={CoverImage} alt="Cover" />

                  <div className={styles.waitlistDesktop}>
                    <RegisterInvite customClass={'song-waitlist-d'}  onSubmit={onSubmit} />
                  </div>
                </div>

                <div style={{'display': 'flex', flexDirection: 'column',}}>
                  <div  style={{'display': 'flex', flexDirection: 'column',}}>
                    <h2 className={styles.welcome}>welcome to</h2>
                    <h3 className={styles.theClub}><span>th</span>e <span className={styles.club} style={{color: 'white'}}>club</span></h3>
                    <h4 className={styles.tagline}>own a bit of the song</h4>
                  </div>

                  <div className={styles.contentSection} >
                    <h5 className={styles.headline}>the ultimate fan club</h5>
                    <p className={styles.text}>Now you can own a bit of <b>Never Forget My Love</b><br className={styles.br} /> and connect with Joss Stone like never before! By <br  className={styles.br} />purchasing a bit of Never Forget My Love, you’ll <br className={styles.br} />unlock exclusive perks such as behind-the-scenes <br className={styles.br} />content and limited-edition merchandise and join <br className={styles.br} /> a very exclusive club for bit holders. </p>
                    <p className={styles.text}>Plus, as a bit-holder, you’ll share in the streaming <br  className={styles.br} /> revenue of the song. It’s the ultimate connection. <br  className={styles.br} /> Don’t just listen—be part of the story. </p>

                    <div className={styles.waitlistMobile}>
                      <RegisterInvite customClass={'song-waitlist-m'} onSubmit={onSubmit}  />
                    </div>
                  </div>

                </div>
                

              </div>


            </div>

          
    </div>
  )

};