import React from "react";
import styles from "./ArtistSection.module.scss";

import CoverImage from "./assets/artist-image.jpg"
import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const ArtistSection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'artist'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>
        <div className={styles.shadow_container}>
          
          <div className={styles.containerMain}>

            <div className={styles.headingMobile}>
              <div>
            <h3 className={styles.theArtist}>
              <div className={styles.desktop}>the ar<span>tist</span></div>
              <div className={styles.mobile}>the arti<span>st</span></div>
              </h3>
            <h4 className={styles.introduction}>an introduction</h4>
            </div>
            <img className={styles.coverimageMobile} src={CoverImage} alt="Cover" />
            </div>

          </div>

          <div className={styles.container}>

              <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>
                
                <div className={styles.contentSection} >
                  <h5 className={styles.headline}>Joss Stone</h5>
                  
                  <div className={styles.scrollingText}>
                    <p className={styles.text}>Joss Stone is a Grammy and Brit Award-winning artist who released her acclaimed debut album, The Soul Sessions, in 2003, at the age of 16. Over her career, she has released nine studio albums, selling over 15 million copies worldwide</p>
                    <p className={styles.text}>Stone has performed and collaborated with legendary artists including James Brown, Burt Bacharach, Stevie Wonder, Gladys Knight, Sting, Van Morrison, Melissa Etheridge, Jeff Beck, Mick Jagger, and Damien Marley, amassing over one billion streams in the US alone</p>
                    <p className={styles.text}>In 2014, she completed her audacious Total World Tour, performing and collaborating with local artists and charities in every country in the world</p>
                    <p className={styles.text}>Her 2022 release, Never Forget My Love, was her eighth studio album, written with and produced by Eurythmics' Dave Stewart, with whom she also co-wrote music and lyrics for the West End musical The Time Traveller's Wife</p>
                    <p className={styles.text}>In 2023, Stone embarked on her "20 Years of Soul" anniversary tour, during which she recorded her first live album, 20 Years of Soul - Live in Concert. She followed this with the Ellipsis Tour in 2024</p>
                    <p className={styles.text}>Her latest single, "Loving You", was released on August 9, 2024. Stone is currently working on a new studio album, scheduled for release in 2025</p>
                  </div>


                  <div className={styles.waitlistMobile}>
                    <RegisterInvite customClass={'artist-waitlist-m'} onSubmit={onSubmit} />
                  </div>
                </div>

              </div>
              
              <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>
                <img className={styles.coverimage} src={CoverImage} alt="Cover" />

                <div className={styles.waitlistDesktop}>
                <RegisterInvite customClass={'artist-waitlist-d'} onSubmit={onSubmit} />
                </div>
              </div>          

          </div>

        </div>

    </div>
  )

};