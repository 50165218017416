import React from "react";
import styles from "./BenefitSection.module.scss";

import Image from "./assets/benefit-image.jpg"
import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const BenefitSection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'benefits'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>
        <div className={styles.shadow_container}>
          <div className={styles.container}>

            <div className={styles.mobileContainer} >
                <h2 className={styles.exclusiveMobile}>exclusive</h2>
                <h3 className={styles.benefitsMobile}>
                  <span className={styles.mobile}><span className={styles.ben}>ben</span>efits</span>
                </h3>
                <h4 className={styles.includedMobile}>included</h4>
                <img className={styles.imageMobile} src={Image} alt="Benefit" />

            </div>

              <div className={styles.desktopImageContainer}>
                <img className={styles.image} src={Image} alt="Benefit" />
              </div>

              <div className={styles.desktopImageContainer}>
                <h2 className={styles.exclusive}>exclusive</h2>
                <h3 className={styles.benefits}>
                  <span className={styles.mobile}><span className={styles.ben}>ben</span>efits</span>
                  <span className={styles.desktop}><span className={styles.b}>b</span>enefits</span>
                </h3>
                <h4 className={styles.included}>included</h4>
                <h5 className={styles.heading}>Featured benefits</h5>
              </div>

          </div>
          
          <div className={styles.containerBottom}>

            <div className={styles.row}>

              <h5 className={styles.headingMobile}>Featured benefits</h5>

              <div style={{'display': 'flex', flexDirection: 'column', flex: 1}}>
                <p className={styles.perk}>
                  Signed Never Forget My Love t-shirt for purchases of $75 or over
                </p>
                <p className={styles.perk}>
                  Personalized message from Joss for purchases of $250 or over
                </p>
                <p className={styles.perk}>
                  Prerecorded guitar lesson from Dave Stewart for the first 20 buyers who spend $500 or over
                </p>
                <p className={styles.perk}>
                  Limited edition Vinyl of the track signed by Joss & Dave for the first 250 buyers who spend $500 or over
                </p>
              </div>

              <div style={{'display': 'flex', flexDirection: 'column', flex: 1}}>
                <p className={styles.perk}>
                  2 Premium gig tickets to a show of your choice by either Joss or Dave for buyers who spend $850 or over
                </p>
                <p className={styles.perk}>
                  Exclusive, custom plaque including in the buyers name as well as exclusive never-before-seen image of Joss and Dave by photographer Dave Hogan for the first 20 buyers who spend $3000 or over 
                </p>
                <p className={styles.perk}>
                  one-on-one guitar lesson from Dave Stewart for the first buyer who spends over $5000
                </p>

                <div className={styles.waitlistContainer}>
                  <RegisterInvite customClass={'benefit-waitlist'} onSubmit={onSubmit} />
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>
  )

};