import React from "react";
import styles from "./FirstSection.module.scss";

import JossStone from "./assets/joss-stone.jpg"
import DownArrow from "./assets/down-arrow.svg"
import VideoBG from "./assets/first-video-low.mp4"

import { Countdown } from "../common";
import { checkLive } from "../../utils/dateCheck";


export const FirstSection = () => {

  const is_live = checkLive("2025-01-21T16:00:00.000Z".split('.')[0]);

  var response = {};

  response['now_epoch'] = is_live.now_epoch
  response['date_epoch'] = is_live.date_epoch
  response['release_date'] = is_live.release_date
  response['date_check'] = is_live.now_epoch > is_live.date_epoch

  const CountDown = () => (
    <div className={styles.countdown}>
      <div className={styles.timer}>
        <Countdown time={response.date_epoch} />
      </div>
    </div>
  );

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth', // Enables smooth scrolling
      block: 'start', // Scrolls to the start of the section
    });
  };

  return (
    <div id="first-section" className={styles.background}>
        <video className={styles.background_video} 
          playsInline={true}
          autoPlay={true}
          loop={true}
          muted={true}>
          <source src={VideoBG} type="video/mp4"  />
        </video>
        <div className={styles.shadow_container}></div>

        <h4 className={styles.hotdrop}>HOT RELEASE</h4>

        <div style={{'display': 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '75vh'}}>

          <div style={{'display': 'flex', flexDirection: 'column'}}>
            <h1 className={styles.artistname}>Joss Stone</h1>
            <h2 className={styles.songname}>never forget my love</h2>
          </div>
          
          <div style={{'display': 'flex', flexDirection: 'column'}}>

            <div className={styles.row}>

              <div style={{'display': 'flex', flexDirection: 'column'}}>
                <img className={styles.artistimage} src={JossStone} alt="Artist" />
              </div>

              <div style={{'display': 'flex', flexDirection: 'column'}}>
              <div style={{'display': 'flex', flexDirection: 'column'}}>
                <h5 className={styles.ownershiprelease}>ownership release</h5>
                <CountDown />
                <h5 className={styles.releasedate}>January 31</h5>
              </div>
              </div>

            </div>


          </div>

        </div>

        <div style={{'display': 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}>
          <img src={DownArrow} className={styles.downArrow} alt="" onClick={() => {
            scrollToSection('song')
          }} />
        </div>

        
    </div>
  )

};