import React, { useEffect, useState } from "react";

// library
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
//import { BeforeReleaseDate } from "../../pages/BeforeReleaseDate/BeforeReleaseDate";
import { HighProfile } from "../../pages/HighProfile";


import ToasterContainer from "../Toaster/Toaster";
import { routes } from "../../../utils/routes";
import { LogoLoader } from "../LogoLoader/LogoLoader";

export const App = () => {
  const [loading, setLoading] = useState(true);

  const onPageLoad = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  };

  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      {loading && <LogoLoader />}
      <BrowserRouter>
        <Routes> 
            <>
              <Route path={routes.home} element={<HighProfile />}>
                  <Route index element={<HighProfile />} />
              </Route>
            </>
        </Routes>
        <ToasterContainer />
      </BrowserRouter>
    </>
  );
};
