import React from "react";
import styles from "./Footer.module.scss";

import { useIntercom } from 'react-use-intercom';

import Image from "./assets/songbits-logo.svg"
import IntercomIcon from "./assets/intercom.png"


export const Footer = () => {

  const { show, boot } = useIntercom();

  function toggleIntercom() {
    boot();
    show();
  }

  return (
    <>
      <div id='footer' className={styles.container}>
        <img className={styles.logo} src={Image} alt="SongBits Logo" />
        <img onClick={toggleIntercom} className={styles.intercom} src={IntercomIcon} alt="Intercom Logo" />
      </div>
      <p className={styles.copyright}>© 2024 Powered by SongBits.com. All Rights Reserved.</p>      
    </>
  );
};
