import React from "react";
import styles from "./StatisticsSection.module.scss";

import FacebookImage from "./assets/socials/facebook-logo.svg"
import InstagramImage from "./assets/socials/instagram-logo.svg"
import TikTokImage from "./assets/socials/tikotk-logo.svg"
import XImage from "./assets/socials/x-logo.svg"
import Logo from "./assets/joss-stone-logo.png"

import SpotifyImage from "./assets/music/spotify-logo.svg"
import YoutubeImage from "./assets/music/youtube-logo.svg"
import SoundcloudImage from "./assets/music/soundcloud-logo.svg"

import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const StatisticsSection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'artist'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>

          <div className={styles.shadow_container}>
            <div className={styles.container}>

                <div style={{'display': 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>

                  <h2 className={styles.artist}>artist</h2>
                  <h3 className={styles.statistics}>statistics</h3>
                  <h4 className={styles.check}>check the stats</h4>
                
                </div>

              </div>
            </div>

            <div className={styles.containerContent}>

              <img src={Logo} className={styles.artistLogoMobile} alt="Artist Logo" />                  
                
              <h5 className={styles.headline}>socials</h5>

              <div className={styles.socialsContainerRow}>
                <div className={styles.socialsContainer}>
                  <div className={styles.socialsItem}>
                    <img className={styles.socials} src={FacebookImage} alt="Facebook Logo" />
                    <p className={styles.count}>1.4m</p>
                    <p className={styles.count}>followers</p>
                  </div>
                  <div className={styles.socialsItem}>
                    <img className={styles.socials} src={InstagramImage} alt="Instagram Logo" />
                    <p className={styles.count}>761k</p>
                    <p className={styles.count}>followers</p>
                  </div>
                  <div className={styles.socialsItem}>
                    <img className={styles.socials} src={XImage} alt="X Logo" />
                    <p className={styles.count}>154k</p>
                    <p className={styles.count}>followers</p>
                  </div>
                  <div className={styles.socialsItem}>
                    <img className={styles.socials} src={TikTokImage} alt="TikTok Logo" />
                    <p className={styles.count}>111.6k</p>
                    <p className={styles.count}>followers</p>
                  </div>
                </div>
                <div>
                  <img src={Logo} className={styles.artistLogo} alt="Artist Logo" />
                </div>
              </div>

            </div>

            <div className={styles.containerContent}>

              <h5 className={styles.headline}>music</h5>

              <div className={styles.socialsContainerRow}>
                <div className={styles.socialsContainer}>
                  <div className={styles.socialsItem}>
                    <img className={styles.socials} src={SpotifyImage} alt="Spotify Logo" />
                    <p className={styles.count}>1.24m</p>
                    <p className={styles.count}>monthly</p>
                  </div>
                  <div className={styles.socialsItem}>
                    <img className={styles.socialsYoutube} src={YoutubeImage} alt="YouTube Logo" />
                    <p className={styles.count}>447k</p>
                    <p className={styles.count}>subscribers</p>
                  </div>
                  <div className={styles.socialsItem}>
                    <img className={styles.socialsSouncloud} src={SoundcloudImage} alt="Soundcloud Logo" />
                    <p className={styles.count}>21.7k</p>
                    <p className={styles.count}>followers</p>
                  </div>
                  <div className={styles.socialsItemEmpty}>
                    <p className={styles.count}></p>
                    <p className={styles.count}></p>
                  </div>
                </div>
                <div>
                  <div className={styles.waitlistConatiner}>
                    <RegisterInvite customClass={'statistics-waitlist'} onSubmit={onSubmit} />
                  </div>            
                </div>
              </div>

            </div>

          
    </div>
  )

};